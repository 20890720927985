<template>
  <div class="refunds-list mx-auto fixed-draggable-dynamic-table-wrapper-height">
    <profile-main-box :profile="refundProfile"
                      :actions-list="actionsList"
                      :default-action="profileStatus"
                      @invoice="handleRowClicked(data[0])"
                      @addon="handleRowClicked(data[1])"
                      @coach="handleRowClicked(data[2])"
    >

      <refunds-profile v-if="data.map(e => e.event).indexOf(profileStatus) > -1" :refund-id="selectedRow.id"/>

      <edit-refund v-if="profileStatus === 'editRefund'" :refund-id="selectedRow.id" :in-modal="true" @edited="handleReloadList"/>
    </profile-main-box>

    <button id="editRefund" v-show="false" @click="handleChangeProfileStatus('editRefund')"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomIcon from "../../../../../components/customIcon/customIcon.vue";
  import RefundsProfile from "../profile/refundsProfile.vue";
  import EditRefund from "../edit/editRefund.vue";
  import ProfileMainBox from "../../../../../components/profileMainBox/profileMainBox2.vue";
  import {getRefundRulesList} from "../../../../../http/requests/club/refundRules";
  import {checkUserPermissions} from "../../../../../assets/js/functions";

  export default {
    name: "refundsList",
    metaInfo () {
      return {
        title: this.$t('refunds.list.title')
      }
    },
    components: {ProfileMainBox, EditRefund, RefundsProfile, CustomIcon},
    data() {
      return {
        profileStatus: '',
        selectedRow: {},
        filters: {},
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          id: 'refundsList',
          rowKeyField: 'id'
        },
        actionsList: [],
        refundTypes: {
          1: {
            name: this.$t('refunds.list.actions.invoice'),
            event: 'invoice',
            icon: 'INVOICE'
          },
          2: {
            name: this.$t('refunds.list.actions.saleAddon'),
            event: 'addon',
            icon: 'PLUS'
          },
          3: {
            name: this.$t('refunds.list.actions.coach'),
            event: 'coach',
            icon: 'USER'
          }
        },
        actions: [
          {
            toolbar: [],
            leftToolbar: [
              {
                id: 'printTable',
                icon: 'PRINT',
                iconPack: 'useral'
              },
              {
                id: 'downloadTable',
                icon: 'DOWNLOAD',
                iconPack: 'useral'
              },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    computed: {
      refundProfile () {
        return {
          name: this.$t('refunds.list.title')
        }
      }
    },
    created() {
      this.$nextTick(() => {
        this.$store.dispatch('auth/setAccessToken')
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
      })

      this.getRefundRules()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getRefundRules() {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.refundsList && this.data.length === 0) this.$refs.refundsList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.refundsList && this.data.length > 0) this.$refs.refundsList.loadMoreStatus = 'Loading'

            getRefundRulesList(this.page).then((response) => {
              const refundsList = response.data
              this.actionsList = []

              refundsList.data.forEach((refund) => {

                this.data.push({
                  id: refund.id,
                  rowNumber: this.data.length + 1,
                  type: this.refundTypes[refund.type].name,
                  event: this.refundTypes[refund.type].event,
                  count: refund.schedules.length
                })

                this.actionsList.push({
                  event: this.refundTypes[refund.type].event,
                  name: 'refundProfile',
                  params: {id: refund.id},
                  header: this.refundTypes[refund.type].name,
                  i18n: '',
                  icon: this.refundTypes[refund.type].icon,
                  iconPack: 'useral',
                  colorCode: '#000000'
                })
              })

              if (this.data.length > 0) {
                this.selectedRow = this.data[0]
                this.profileStatus = this.data[0].event
              }

              this.total_count = refundsList.pagination.total
              this.page = refundsList.pagination.current_page + 1


              if (this.$refs.refundsList) this.$refs.refundsList.loadMoreStatus = ''
            }).catch((error) => {
              if (this.$refs.refundsList && !axios.isCancel(error)) this.$refs.refundsList.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
      },
      handleRowClicked(row) {
        this.selectedRow = row
        this.handleChangeProfileStatus(row.event)
      },
      handleChangeProfileStatus (status) {
        if (this.profileStatus !== status) {
          this.profileStatus = ''
          this.$nextTick(() => {
            this.profileStatus = status
          })
        }
      },
      handleReloadList() {
        this.data = []
        this.page = 1
        this.total_count = 0
        this.getRefundRules()
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style scoped>
  .refunds-list {
    height: calc(100vh - 150px);
    background-color: #ffffff;
    border: 1px solid #cdcdcd;
    border-top: 0;
    border-radius: 0 0 0.5rem 0.5rem;
  }
</style>
